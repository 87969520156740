@import '../../../../assets/css/variables.scss';
$table-border: #d9d9d9;
$row-bg: #f4f2f1;


$small-screen-size: 550px;      // the width to change to mobile version
$table-small-size-fixed: 400px; // table min size

.table_icon{
  cursor: pointer;
  transition: 0.5s;
  color: black;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;

  &_ok {
    margin-left: 10px;
  }
  &_no_ok {
    margin-left: 10px;
  }

  @media only screen and (max-width: $small-screen-size) {
    display: inline-flex;
    &_ok {
      margin-left: 0px;
    }
    &_no_ok {
      margin-left: 10px;
    }
  }
}

.icon_container {
  @media only screen and (max-width: $small-screen-size) {
    display: inline-flex
  }
}



.table_container {
  display: block;
  width: 100%;
  max-width: 1000px;
  margin-top: 50px;
  //border-top: solid 1px $table-border;
  //border-bottom: solid 1px $table-border;
  @media only screen and (max-width: $small-screen-size) {
    width: 100%;
    overflow-x: scroll;
    margin: 0;
  }
}

.flex_table {
  display: grid;
  min-width: $table-small-size-fixed;
  grid-template-columns: 11% 9% 35% 10% 35%;
  grid-template-rows: 100% auto;
  transition: 0.5s;
  &.row:nth-child(odd) {
    background: $row-bg;
  }
  &.row:hover {
    background: #f5f5f5;
    transition: 0.5s;
  }
  &_header{
    background-color: map-get($colors, liberty-yellow);
    color: black;
  }
  &_row {
    display: block;
    width: 100%;
    padding: 0.5em 0.5em;
    word-break: break-all;

    &_item_check {
      text-align: center;
    }
    &_item_code {
      text-align: center;
    }
    &_item_reason {
      text-align: left;
    }
    &_item_name {
      text-align: left;
    }
    &_item_amount {
      text-align: center;
    }
  }

  @media only screen and (max-width: $small-screen-size) {
    grid-template-columns: 45% 55%;
    font-size: smaller;
    &_row {
      &_item_name {
        text-align: left;
      }
    }
  }
  @media only screen and (min-width: $small-screen-size) and (max-width: 850px) {
    grid-template-columns: 25% 15% 25% 15% 20%;
  }
}








.modal {
  &_error {
    color: map-get($colors, liberty-red);
    margin-top: 10px;
  }
  &_container{
    flex-direction: row;
    display: flex;
  }
  &_icon {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: auto;
    margin-bottom: auto;
    color: map-get($colors, liberty-blue)
  }
  &_item {
    //padding: 15px;
    padding-left: 0px;
    padding-right: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    width: 100%;
    margin-bottom: 20px;

    @media only screen and (max-width: $small-screen-size) {
      font-size: smaller;
    }
    &_reason {
      margin-top: 15px;
      margin-bottom: 10px;
      font-weight: bold;
    }
    &_selected {
      margin-bottom: 10px;
      font-weight: bold;
    }
    &_input {
      width: 100%;
      resize: none;
      &_sub_text {
        text-align: right;
        color: map-get($colors, liberty-black);
        opacity: 0.5;
        margin-top: 5px;
        margin-right: 5px;
      }
    }
  }
}



