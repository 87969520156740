@import './variables.scss';

.wrapper {
  width: 1200px;
  max-width: 90%;
}
.header_one {
  font-size: 3.25rem;
  color: map-get($colors, liberty-blue);
  font-weight: map-get($font-weight, medium);
  margin: 0;
}

.header_two {
  font-size: 2.375rem;
  color: map-get($colors, liberty-blue);
  font-weight: map-get($font-weight, medium);
  margin: 0;
}

.header_three {
  font-size: 1.625rem;
  color: map-get($colors, liberty-blue);
  font-weight: map-get($font-weight, medium);
  margin: 0;
}

.header_four {
  font-size: 1.375rem;
  color: map-get($colors, liberty-blue);
  font-weight: map-get($font-weight, medium);
  margin: 0;
}

.paragraph {
  font-size: 1.125rem;
  color: map-get($colors, liberty-blue);
  font-weight: map-get($font-weight, regular);
  margin: 0;
}

.small_paragraph {
  font-size: 1rem;
  color: map-get($colors, liberty-dark-gray);
  font-weight: map-get($font-weight, regular);
  margin: 0;
  line-height: 1.375rem;
}

.label_desktop {
  font-size: 1rem;
  color: map-get($colors, liberty-blue);
  font-weight: map-get($font-weight, medium);
  margin: 0;
  line-height: 1.25rem;
}

.status_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  &_approved {
    color: map-get($colors, liberty-green);
    &:before {
      font-family: 'icomoon' !important;
      content: '\e904';
      font-size: 2rem;
    }
  }
  &_pending {
    color: map-get($colors, liberty-yellow-medium);
    &:before {
      font-family: 'icomoon' !important;
      content: '\e903';
      font-size: 2rem;
    }
  }
  &_repairing {
    color: map-get($colors, liberty-green);
    &:before {
      font-family: 'icomoon' !important;
      content: '\e904';
      font-size: 2rem;
    }
  }
  &_pendingPayment {
    color: map-get($colors, liberty-yellow-medium);
    &:before {
      font-family: 'icomoon' !important;
      content: '\e903';
      font-size: 2rem;
    }
  }
  &_rejected {
    color: map-get($colors, liberty-red);
    &:before {
      font-family: 'icomoon' !important;
      content: '\e905';
      font-size: 2rem;
    }
  }
}
