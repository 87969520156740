@import "../../../../assets/css/variables.scss";
@import "../../../../assets/css/general_styles.scss";

.form_consulta_siniestro {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
  flex-grow: 1;
  text-align: center;
  position: relative;
  &_title {
    @extend .header_two;
    font-size: 1.375rem;
    margin: 0 0 1rem 0;
  }
  &_subtitle {
    @extend .small_paragraph;
    color: map-get($colors, liberty-blue);
    margin: 2.5rem 0 1.5rem 0;
  }
  &_info {
    @extend .small_paragraph;
    color: map-get($colors, liberty-blue);
    margin: 2.5rem 0 1.5rem 0;
  }
  &_cnt {
    display: block;
    width: 100%;
    max-width: 400px;
    height: 235px;
    position: relative;
    &_asegurado {
      width: 100%;
      transition: 0.4s ease;
      opacity: 1;
      visibility: visible;
      position: absolute;
      margin-top: rem;
      &_hidden {
        transform: translateX(100%);
        opacity: 0;
        visibility: hidden;
      }
      &_input {
        width: 100%;
        max-width: 260px;
        margin: 0 auto 1rem auto;
      }
      &_datepicker {
        margin-bottom: 2rem;
      }
      &_button {
        margin-bottom: 2rem;
      }
      &_link {
        cursor: pointer;
        display: flex;
        justify-content: center;
        flex-direction: column;
      }
    }
    &_tercero {
      position: absolute;
      width: 100%;
      transition: 0.4s ease;
      opacity: 1;
      visibility: visible;
      margin-top: 1.5rem;
      &_text {
        @extend .small_paragraph;
        margin-bottom: 1.5rem;
      }
      &_hidden {
        transform: translateX(-100%);
        opacity: 0;
        visibility: hidden;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .form_consulta_siniestro {
    &_title {
      font-size: 2.375rem;
    }
    &_input {
      max-width: 265px;
    }
    .recaptcha {
      margin-bottom: 3rem;
    }
  }
}
