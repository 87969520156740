@import '../../../assets/css/variables.scss';

.page_container {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  text-align: center;
  padding: 4.875rem 2.25rem 4.25rem 2.25rem;
  .info_container {
    display: block;
    color: map-get($colors, liberty-dark-gray);
    width: 250px;
    &-image {
      display: block;
      margin: 0 auto 1.25rem auto;
      height: 80px;
      width: 90px;
      .img {
        display: block;
        height: 100%;
        width: 100%;
      }
    }
    &-title {
      display: block;
      margin: 0 auto 2.25rem auto;
      font-size: 2.25rem;
      font-weight: map-get($font-weight, medium);
    }
    &-content {
      display: block;
      width: 100%;
      font-size: 0.875rem;
      margin-bottom: 1.75rem;
    }
  }
}

@media only screen and (min-width: 768px) {
  .page_container {
    .info_container {
      width: 100%;
      &-image {
        height: 128px;
        width: 142px;
        margin-bottom: 1.5rem;
      }
      &-title {
        font-size: 3.125rem;
      }
      &-content {
        margin: 0 auto 1.75rem auto;
        width: 500px;
        font-size: 1.125rem;
      }
    }
  }
}
