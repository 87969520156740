@import './assets/css/normalize.css';
@import './assets/icons/style.css';
@import './assets/css/variables.scss';

* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-size: 16px; // 1REM
  font-family: map-get($fonts, roboto);
  height: 100%;
  position: relative;
  background: map-get($colors, liberty-white);
}
#root {
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  position: relative;
  flex-flow: wrap column;
}
.strong {
  font-weight: map-get($font-weight, medium);
}
