@import '../../../../../assets/css/variables.scss';
@import '../../../../../assets/css/general_styles.scss';

.container {
  display: flex;
  flex-flow: column;
}

@media only screen and (min-width: 768px) {
  .container {
    flex-flow: row;
  }
}
