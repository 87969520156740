@import '../../../assets/css/variables.scss';
@import '../../../assets/css/general_styles.scss';

.custom_input {
  display: flex;
  flex-flow: row wrap;

  .div_tooltip {
    width: 0%;
    height: 100%;
  }

  .input_label {
    color: map-get($colors, liberty-black);
    opacity: 0.8;
    margin-bottom: 5px;
  }

  .input_required {
    color: map-get($colors, liberty-red);
  }

  .input_container {
    display: flex;
    width: 100%;
    margin: 0 auto 0.75rem auto;
    align-items: center;
    position: relative;
    border-bottom: 1px solid map-get($colors, liberty-blue);


    .input {
      display: block;
      text-align: center;
      font-size: 0.875rem;
      width: 100%;
      border: 0;
      outline: none;
      padding: 0.375rem;
      box-shadow: none;
      flex-grow: 1;
      background: transparent;

      &::placeholder {
        color: map-get($colors, liberty-blue);
        opacity: 0.6;
      }
    }

    &_info {
      position: absolute;
      right: -20px;
      color: map-get($colors, liberty-blue);
      background-color: map-get($colors, liberty-white);
      border-radius: 50%;
      margin-left: 0.125rem;
      cursor: pointer;
      width: 24px;
      height: 24px;
      padding: 4px;

      .icon {
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    .tooltip {
      opacity: 0;
      visibility: hidden;
      text-align: left;
      font-size: 0.75rem;
      padding: 1rem 0.875rem;
      display: block;
      position: absolute;
      border-radius: 0.625rem;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
      z-index: 1;
      background-color: map-get($colors, liberty-white);
      color: map-get($colors, liberty-blue);
      top: calc(100% + 8px);
      width: 100%;
      right: 0;
      transition: 0.3s ease all;

      &.tooltip__open {
        opacity: 1;
        visibility: visible;
      }
    }

    .tooltip_custom {
      color: #fff !important;
      background-color: #1A1446 !important;
      max-width: 200px !important;
      border-radius: 0.625rem;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    }
  }

  .error {
    width: 100%;
    font-size: 0.75rem;
    color: map-get($colors, liberty-red);
    text-align: center;
    opacity: 0;
    transition: 0.2s ease all;
    min-height: 26px;
  }
}

.custom_input.custom_input_error {
  .error {
    opacity: 1;
  }
}

.__react_component_tooltip.type-info {
  color: grey !important;
  background-color: #fff !important;
  border: 1px #222;
  max-width: 200px !important;
}


@media only screen and (min-width: 768px) {
  .custom_input {
    .input_container {
      &_info {
        margin-left: 0.75rem;
        width: 20px;
      }

      .tooltip {
        width: calc(100% + 10%);
        font-size: 0.625rem;
        padding: 1.125rem 1rem;
        left: -2%;
        top: calc(100% + 8px);
      }
    }
  }
}

@media only screen and (min-width: 990px) {
  .custom_input {
    .input_container {
      &_info {
        width: 20px;
      }

      .tooltip {
        width: calc(100% + 10%);
        font-size: 0.75rem;
        padding: 1.125rem 1rem;
        left: 101%;
        top: 100%;
      }
    }
  }
}