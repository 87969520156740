@import '../../../../../../assets/css/variables.scss';
@import '../../../../../../assets/css/general_styles.scss';

.container {
  display: flex;
  flex-flow: column;
  width: 100%;
  position: relative;
  padding-left: 65px;
  min-height: 60px;
  margin-bottom: 2.125rem;
  &:last-of-type {
    margin-bottom: 0;
  }
  .date {
    @extend .small_paragraph;
    font-size: 0.875rem;
    min-height: 22px;
  }
  .tooltip {
    opacity: 0;
    visibility: hidden;
    text-align: left;
    font-size: 0.75rem;
    padding: 1rem 0.875rem;
    display: block;
    position: absolute;
    border-radius: 0.625rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    z-index: 1;
    background-color: map-get($colors, liberty-white);
    color: map-get($colors, liberty-blue);
    top: calc(100% + 8px);
    width: 100%;
    right: 0;
    transition: 0.3s ease all;
    &.tooltip__open {
      opacity: 1;
      visibility: visible;
    }
  }
  .figure_container {
    position: absolute;
    left: 0;
    top: 10px;
    .figure {
      width: 40px;
      height: 40px;
      position: relative;
      left: 0;
      &_caption {
        @extend .status_icon;
        position: absolute;
        top: -2px;
        right: -5px;
        background: map-get($colors, liberty-white);
        border-radius: 50%;
        height: 0.875rem;
        width: 0.875rem;
        &:before {
          font-size: 0.875rem;
        }
        &_approved {
          @extend .status_icon_approved;
        }
        &_pending {
          @extend .status_icon_pending;
        }
        &_pendingPayment {
          @extend .status_icon_pending;
        }
        &_repairing {
          @extend .status_icon_approved;
        }
        &_rejected {
          @extend .status_icon_rejected;
        }
      }
      &_img {
        width: 100%;
      }
    }
  }
  .label {
    @extend .label_desktop;
    width: 100%;
  }
  .helpText {
    @extend .small_paragraph;
    color: map-get($colors, liberty-dark-gray);
    width: 100%;
    margin-top: 0.5rem;
  }
  .button {
    margin: 0.5rem auto 0 0;
    width: 100%;
  }
  .substepInfo {
    margin-top: 1.875rem;
  }
}

@media only screen and (min-width: 768px) {
  .container {
    display: flex;
    flex-flow: column;
    align-items: center;
    text-align: center;
    padding: 0 0.25rem;
    flex-grow: 1;
    width: 10px;
    margin-bottom: 0;
    .figure_container {
      position: static;
      .figure {
        width: 70px;
        height: 70px;
        position: relative;
        margin: 1.25rem 0;
        &_caption {
          position: absolute;
          top: -4px;
          right: -8px;
          width: 1.5rem;
          height: 1.5rem;
          &:before {
            font-size: 1.5rem;
          }
        }
      }
    }
    .label {
      width: 100%;
      height: 40px;
    }
    .helpText {
      color: map-get($colors, liberty-dark-gray);
      width: 100%;
      height: 54px;
      margin-top: 1rem;
    }
    .button {
      width: 204px;
      margin: 1rem auto 0 auto;
    }
    .tooltip {
      width: calc(100% + 10%);
      font-size: 0.625rem;
      padding: 1.125rem 1rem;
      left: -2%;
      top: calc(100% + 8px);
    }
    .icon {
      display: block;
      padding: 5px;
      width: 100%;
      height: 100%;
    }
    .tooltip_documentos{
      background: #ffffff;
      color: #1a1446;
      border-radius:0.625rem;
      box-shadow:0 2px 4px rgba(0, 0, 0, 0.5);
    }
    .span_tooltip{
      display:inline-block;
    }
  }
}
