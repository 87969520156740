@import '../../../../../../../../assets/css/variables.scss';
@import '../../../../../../../../assets/css/general_styles.scss';

.container {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  &:last-of-type {
    margin: 0;
  }
  .icon {
    @extend .status_icon;
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    margin-right: 1rem;
    &:before {
      //font-family: 'icomoon' !important;
      font-size: 0.875rem;
    }
    &_approved {
      @extend .status_icon_approved;
    }
    &_pending {
      @extend .status_icon_pending;
      background: none;
      color: #727270;
      &:before {
        content: '\e906';
        font-size: 0.875rem;
      }
    }
    &_repairing {
      @extend .status_icon_approved;
    }
    &_rejected {
      @extend .status_icon_rejected;
    }
    &_default {
      &:before {
        content: '\e907';
        font-size: 0.5rem;
        color: map-get($colors, liberty-blue);
      }
    }
  }
  .info {
    text-align: left;
    &_date {
      @extend .small_paragraph;
      line-height: 1.25rem;
      font-size: 0.875rem;
      color: map-get($colors, liberty-dark-gray);
      font-weight: map-get($font-weight, medium);
    }
    &_label {
      @extend .small_paragraph;
      line-height: 1rem;
      font-size: 0.875rem;
      color: map-get($colors, liberty-dark-gray);
    }
  }
}
