@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?k68nfw');
  src:  url('fonts/icomoon.eot?k68nfw#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?k68nfw') format('truetype'),
    url('fonts/icomoon.woff?k68nfw') format('woff'),
    url('fonts/icomoon.svg?k68nfw#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-burguermenu:before {
  content: "\e908";
}
.icon-location:before {
  content: "\e90d";
}
.icon-chevron-thin-down:before {
  content: "\e90c";
}
.icon-instagram:before {
  content: "\e90b";
}
.icon-info:before {
  content: "\e90a";
}
.icon-phone:before {
  content: "\f095";
}
.icon-twitter:before {
  content: "\f099";
}
.icon-facebook:before {
  content: "\f09a";
}
.icon-facebook-f:before {
  content: "\f09a";
}
.icon-salir:before {
  content: "\e909";
}
.icon-dot:before {
  content: "\e907";
}
.icon-process:before {
  content: "\e906";
}
.icon-advertencia:before {
  content: "\e903";
}
.icon-check:before {
  content: "\e904";
}
.icon-rechazo:before {
  content: "\e905";
}
.icon-close:before {
  content: "\e901";
}
.icon-whatsapp:before {
  content: "\e900";
}
.icon-linkedin2:before {
  content: "\eaca";
}
