@import '../../../assets/css/variables.scss';

.recaptcha {
  display: inline-block;
  &.recaptcha__error {
    border: 3px solid map-get($colors, liberty-red);
    width: 308px;
    height: 82px;
  }
}
.main_container {
  padding: 1.5rem 5% 1.5rem;
}
.modal_content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 0.875rem 0.75rem 1.5rem 0.75rem;
  &_icon {
    color: map-get($colors, liberty-blue);
    font-size: 1.75rem;
    margin-right: 0.75rem;
  }
  &_text {
    color: map-get($colors, liberty-blue);
    font-size: 0.75rem;
    .strong {
      display: block;
      font-size: 0.75rem;
      font-weight: map-get($font-weight, medium);
      margin-bottom: 0.375rem;
    }
  }
}
@media only screen and (min-width: 768px) {
  .modal_content {
    padding: 0.75rem 3.75rem 2.5rem 2.5rem;
    &_icon {
      font-size: 3rem;
      margin-right: 2.5rem;
    }
    &_text {
      font-size: 1.125rem;
    }
  }
}
