.app {
  display: flex;
  flex-flow: column;
  position: relative;
  flex-grow: 1;
  padding-bottom: 150px;
  &.app_padding_none {
    padding-bottom: 0;
  }
}
