@import '../../../assets/css/variables.scss';

.contact {
  position: absolute;
  right: 2rem;
  bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  &.contact--fixed {
    position: fixed;
    .contact_item {
      &_text {
        opacity: 0;
        visibility: hidden;
        transform: translateX(0);
      }
    }
  }
  a {
    text-decoration: none;
  }
  .pulse {
    width: 40px;
    height: 40px;
    background: map-get($colors, liberty-yellow);
    border-radius: 50%;
    animation: animate 2s linear infinite;
  }
  &_item {
    position: relative;
    min-height: 50px;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    &:last-of-type {
      margin: 0;
    }
    &_icon {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 40px;
      font-size: 1.25rem;
      border-radius: 50%;
      color: map-get($colors, liberty-white);
      cursor: pointer;
      background: map-get($colors, liberty-yellow);
    }
    .whatsapp_detail {
      position: absolute;
      width: 180px;
      padding: 0.5rem;
      background: white;
      border-radius: 0.25rem;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.28);
      color: map-get($colors, liberty-blue);
      transform: translateX(0);
      opacity: 0;
      visibility: hidden;
      transition: 0.2s ease-in opacity, visibility;
      &_title {
        font-size: 0.625rem;
        font-weight: map-get($font-weight, medium);
        margin: 0;
      }
      &_text {
        font-size: 0.625rem;
        margin: 0;
      }
    } // whatsapp_detail
    &_text {
      display: block;
      position: absolute;
      width: 216px;
      opacity: 1;
      visibility: visible;
      text-align: right;
      line-height: 1.125rem;
      transition: 0.2s ease-in all;
      transform: translateX(calc(-100% - 0.5rem));
      .strong {
        display: block;
        font-size: 0.8125rem;
        font-weight: map-get($font-weight, medium);
      }
      .text {
        display: block;
        font-size: 0.75rem;
      }
    } // contact_text
  }
}

.modal_content {
  display: flex;
  align-items: center;
  justify-content: left;
  flex-flow: row wrap;
  flex: 1;
  padding: 0 1.625rem 0.625rem 1.625rem;
  color: map-get($colors, liberty-blue);
  &_title {
    font-size: 0.75rem;
    font-weight: map-get($font-weight, medium);
    margin: 0 0 4px 0;
    width: 100%;
  }
  &_text {
    font-size: 0.75rem;
    margin: 0;
    width: 100%;
  }
} // modal_content

@keyframes animate {
  0% {
    box-shadow: 0 0 0 0 rgba(map-get($colors, liberty-yellow), 0.7),
      0 0 0 0 rgba(map-get($colors, liberty-yellow), 0.7);
  }
  40% {
    box-shadow: 0 0 0 20px rgba(map-get($colors, liberty-yellow), 0),
      0 0 0 0 rgba(map-get($colors, liberty-yellow), 0.7);
  }
  80% {
    box-shadow: 0 0 0 20px rgba(map-get($colors, liberty-yellow), 0),
      0 0 0 0 rgba(map-get($colors, liberty-yellow), 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(map-get($colors, liberty-yellow), 0),
      0 0 0 0 rgba(map-get($colors, liberty-yellow), 0);
  }
}

@media only screen and (min-width: 768px) {
  .contact {
    &_item {
      &:hover {
        .whatsapp_detail {
          opacity: 1;
          visibility: visible;
          transform: translateX(calc(-100% - 0.5rem));
        }
      }
    }
  }
}
