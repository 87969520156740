@import '../../../assets/css/variables.scss';

.button {
  display: block;
  text-align: center;
  color: map-get($colors, liberty-white);
  font-size: 0.75rem;
  font-weight: map-get($font-weight, medium);
  //border-radius: 1.125rem;
  padding: 0.625rem 1.875rem;
  border: 0;
  transition: 0.3s ease all;
  text-decoration: none;
  text-transform: uppercase;
  opacity: 1;
  outline: none;
  margin: 0 auto;
  cursor: pointer;
  width: fit-content;
  &:disabled {
    opacity: 0.6;
    cursor: default;
  }
}

.button_primary_teal {
  background: map-get($colors, liberty-teal);
  &:hover {
    opacity: 0.8;
  }
}

.button_primary_dark_teal {
  background: map-get($colors, liberty-dark-teal);
  color: map-get($colors, liberty-white);
  &:hover {
    background: map-get($colors, liberty-teal);
    color: map-get($colors, liberty-blue);
  }
}

.button_primary_yellow {
  background: map-get($colors, liberty-blue);
  &:hover {
    opacity: 0.8;
    &:disabled {
      opacity: 0.6;
    }
  }
}

.button_primary_blue {
  background: map-get($colors, aliro-blue);
  color: white;
  &:hover {
    opacity: 0.8;
  }
}

.button_secondary_artmosphery_white {
  background: map-get($colors, liberty-artmosphery-white);
  &:hover {
    background-color: rgba(map-get($colors, liberty-artmosphery-white), 0.8);
  }
}

@media only screen and (min-width: 768px) {
  .button {
    font-size: 1rem;
  }
}
