@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');

$fonts: (
  roboto: #{'Roboto',
  sans-serif}
);

$font-weight: (
  light: 300,
  regular: 400,
  medium: 500
);

$colors: (
  liberty-header-white: #fff,
  liberty-yellow: #65a518,
  liberty-yellow-rgb: rgb(101, 165, 24),
  liberty-yellow-medium: #f8e71c,
  liberty-yellow-medium-rgb: rgb(248, 231, 28),
  liberty-light-yellow: #d3d3d399,
  liberty-light-yellow-rgb: rgb(247, 235, 197),
  liberty-blue: #006729,
  liberty-blue-rgb: rgb(0, 103, 41),
  liberty-light-blue: #00205b,
  liberty-light-blue-rgb: rgb(0, 32, 91),
  liberty-lighter-blue: #1a376c,
  liberty-lighter-blue-rgb: rgb(26, 55, 108),
  liberty-teal: #65a518, 
  liberty-teal-rgb: rgb(101, 165, 24),
  liberty-dark-teal: #06748c,
  liberty-dark-teal-rgb: rgb(6, 116, 140),
  liberty-artmosphery-white: #d8d8d8,
  liberty-artmosphery-white-rgb: rgb(216, 216, 216),
  liberty-white: #fff,
  liberty-white-rgb: rgb(255, 255, 255),
  liberty-gray: #e0e0df,
  liberty-gray-rgb: rgb(224, 224, 223),
  liberty-dark-gray: #53565a,
  liberty-dark-gray-rgb: rgb(83, 86, 90),
  liberty-black: black,
  liberty-black-rgb: rgb(0, 0, 0),
  liberty-red: #d9011c,
  liberty-red-rgb: rgb(217, 1, 28),
  liberty-green: #7ed321,
  liberty-green-rgb: rgb(126, 211, 33),
  whatsapp-green: #25d366,
  whatsapp-green-rgb: rgb(37, 211, 102),
  aliro-blue: rgb(92, 136, 218),
);
