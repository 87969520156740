.MuiFormControl-root {
    border: 1px solid #DADADA !important;
    border-radius: 6px;
    width: 320px;
}

.MuiDialog-paper {
    margin: 0px !important;
    border: 0.5px solid #707070;
}

.MuiPaper-rounded {
    border-radius: 2px !important;
}

.MuiFilledInput-root {
    background-color: transparent !important;
}

.MuiFilledInput-input{
    color: #565656 !important
}

.MuiPickersToolbar-toolbar {
    background-color: #65a518 !important;
    height: 148px !important;
    justify-content: start !important;
    padding-top: 24px;
}

.MuiPickersDay-daySelected {
    background-color: #65a518 !important;
    color: #fff;
}

.MuiInputLabel-filled {
    transform: translate(70px, 20px) scale(1) !important;
}

.MuiInputLabel-filled.MuiInputLabel-shrink {
    transform: translate(70px, 7px) scale(0.75) !important;
}

.MuiButton-textPrimary {
    color: #65a518 !important;
}

.MuiButton-root {
    color: rgba(0, 0, 0, 0.87);
    padding: 6px 16px;
    font-size: 0.875rem;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 10, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 4px;
    letter-spacing: 0.02857em;
    text-transform: none !important;
}

.MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel) {
    margin-top: 7px !important;
    margin-left: -13px;
    padding: 16px 0px 3px 0px !important;
}

.MuiFilledInput-adornedEnd {
    padding-right: 12px;
    display: flex;
    flex-direction: row-reverse;
}

.MuiInputAdornment-positionEnd {
    margin-left: 10px;
    margin-top: 0px;
}

.MuiIconButton-root.Mui-disabled {
    color: #fff !important;
}

.MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.2) !important;
}