@import '../../../assets/css/variables.scss';
.backdrop {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(26, 20, 70, 0.3);
  z-index: 2;
  visibility: hidden;
  opacity: 0;
  transition: 0.2s ease all;
  &_show {
    visibility: visible;
    opacity: 1;
  }
}
