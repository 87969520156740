@import '../../../assets/css/variables.scss';

.modal_container {
  position: fixed;
  display: flex;
  flex-flow: row wrap;
  width: 80%;
  max-width: 600px;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  z-index: 3;
  opacity: 0;
  visibility: hidden;
  transition: 0.2s ease all;
  &_buttons {
    display: flex;
  }
  &_show {
    opacity: 1;
    visibility: visible;
    display: flex;
    flex-direction: column;
  }
  .button_modal_close {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-end;
    margin: 8px 8px 0 0;
    &_icon {
      padding: 0.25rem;
      color: map-get($colors, liberty-red);
      font-size: 1rem;
      display: block;
    }
  }
  .button {
    text-transform: uppercase;
    text-decoration: none;
    background: map-get($colors, liberty-teal);
    color: map-get($colors, liberty-blue);
    font-size: 0.75rem;
    font-weight: map-get($font-weight, medium);
    text-align: center;
    padding: 0.5rem;
    width: 100%;
    border: 0;
    border-radius: 0;
    cursor: pointer;
    &_icon {
      margin-right: 5px;
    }
  }
  .buttonMargin {
    border-right: 2px solid white;
  }
}

@media only screen and (min-width: 768px) {
  .modal_container {
    .button {
      font-size: 1rem;
      padding: 0.625rem 0;
    }
    .button_modal_close {
      margin: 18px 18px 0 0;
    }
  }
}
