@import '../../../assets/css/variables.scss';

.switch_selector {
  position: relative;
  display: inline-block;
  width: 240px;
  height: 32px;
  &_options {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    border: 1px solid map-get($colors, liberty-teal);
    border-radius: 32px;
    transition: 0.4s;
    &_item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      width: 100%;
      font-weight: map-get($font-weight, medium);
      font-size: 0.875rem;
      border-radius: 16px;
      transition: 0.4s ease;
      color: map-get($colors, liberty-teal);
      &_selected {
        background-color: map-get($colors, liberty-teal);
        color: white;
      }
    }
  }
}
