@import '../../../../assets/css/variables.scss';
@import '../../../../assets/css/general_styles.scss';

.tracking {
  &_title {
    @extend .header_three;
    display: none;
    margin-bottom: 4.75rem;
  }
  .update_at_container {
    font-size: 0.75rem;
    margin-bottom: 2rem;
    text-align: right;
    color: map-get($colors, liberty-blue);
    &_title {
      display: block;
      width: 100%;
      font-weight: map-get($font-weight, medium);
      margin-bottom: 0.25rem;
    }
    &_value {
      display: block;
      width: 100%;
    }
  }
  .tracking_info {
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 2rem;
    &_progress {
      width: 50px;
      padding: 1.5rem 0 0 1.25rem;
      &_bar {
        width: calc(100% - 30px);
        margin: 0 auto 0 0;
        height: 8px;
        background-color: rgba(26, 20, 70, 0.2);
        border-radius: 0.25rem;
        transform: rotate(90deg);
        transform-origin: left;
        &_completed {
          position: relative;
          height: 100%;
          width: 0%;
          max-width: 100%;
          background-color: map-get($colors, liberty-blue);
          opacity: 1;
          border-radius: 0.25rem;
          transition: 0.5s ease height;
          &_25 {
            width: 25%;
          }
          &_33 {
            width: 33%;
          }
          &_50 {
            width: 50%;
          }
          &_66 {
            width: 66%;
          }
          &_75 {
            width: 75%;
          }
          &_100 {
            width: 100%;
          }
          &_item {
            position: absolute;
            right: -18px;
            bottom: -2px;
            width: 40px;
            transform: rotate(-90deg);
          }
        } // _completed
      } // _bar
    } // _progress
    &_steps {
      width: 10px;
      flex-grow: 2;
    }
  }
}

@media only screen and (min-width: 768px) {
  .tracking {
    &_title {
      display: block;
      margin-bottom: 0.675rem;
    }
    .update_at_container {
      font-size: 1rem;
      margin-bottom: 5.75rem;
    }
    .tracking_info {
      display: flex;
      flex-flow: row wrap;
      margin-bottom: 5.625rem;
      &_progress {
        width: 100%;
        margin: 2.625rem 0 2rem 0;
        padding: 0;
        &_bar {
          width: calc(100% - (100% / 5));
          margin: 0 auto;
          transform: rotate(0);
          &_completed {
            &_item {
              right: -60px;
              bottom: 10px;
              width: 120px;
              transform: rotate(0);
            }
          } // _completed
        } // _bar
      } // _progress
    }
  }
}
