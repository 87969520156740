@import '../../../assets/css/variables.scss';

.footer_container {
  padding: 2rem;
  width: 100%;
  color: map-get($colors, liberty-blue);
  background: map-get($colors, liberty-teal);
  .footer {
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    &_logo {
      width: 10px;
      flex-grow: 1;
      margin-right: 1rem;
      .logo {
        max-width: 130px;
        &_img {
          width: 100%;
        }
      }
    }
    &_links {
      display: none;
      justify-content: space-between;
      align-items: flex-start;
      flex-grow: 1;
      margin: 0 2rem;
      .list {
        margin: 0;
        padding: 0;
        list-style: none;
        width: 20%;
        line-height: 1.125rem;
        &_item {
          font-weight: map-get($font-weight, light);
          font-size: 0.875rem;
          .title {
            color: white;
            font-weight: map-get($font-weight, medium);
          }
          .link {
            color: white;
            &:link {
              text-decoration: none;
            }
            &:hover {
              text-decoration: underline;
            }
          }
        }
      } // list
    } // &_links
    &_social {
      max-width: 175px;
      width: 10px;
      flex-grow: 1;
      display: flex;
      justify-content: center;
      flex-flow: row wrap;
      align-self: center;
      .title {
        width: 100%;
        text-align: center;
        font-size: 0.875rem;
        margin-bottom: 0.625rem;
      }
      .link {
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        color: map-get($colors, liberty-blue);
        font-size: 1.25rem;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-bottom: 15px;
        background: white;
        &.link--nobackground {
          width: auto;
          height: auto;
          background: transparent;
        }
        &:nth-of-type(2) {
          margin: 0 0.5rem;
        }
        &:nth-of-type(4) {
          margin-top: 0.5rem;
        }
        .image {
          width: 100%;
        }
      }
    }
  } // footer
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .footer_container {
    padding: 1.5rem 1.5rem 2.5rem 1.5rem;
    .footer {
      &_logo {
        display: none;
      }
      &_links {
        display: flex;
      } // &_links
      &_social {
        .title {
          margin-bottom: 0.5rem;
        }
      }
    } // footer
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .footer_container {
    width: 100%;
    .footer {
      width: 100%;
      margin: 0 auto;
      max-width: 1170px;
      display: flex;
      font-weight: map-get($font-weight, regular);
      font-size: 0.75rem;
      &_logo {
        display: block;
      }
    } // footer
  }
}
