@import '../../../../assets/css/variables.scss';
@import '../../../../assets/css/general_styles.scss';

.container {
  display: flex;
  flex-flow: column;
  color: map-get($colors, liberty-blue);
  margin-bottom: 2rem;
  .claim_info {
    display: flex;
    width: 100%;
    margin-bottom: 2.625rem;
    align-items: center;
    flex-flow: row wrap;
    &_title {
      display: block;
      font-size: 1.25rem;
      font-weight: map-get($font-weight, medium);
      flex-grow: 1;
    }
    &_text {
      display: block;
      font-size: 1rem;
      width: 100%;
      margin: 1rem 0 0 0;
      &_strong {
        font-weight: map-get($font-weight, medium);
      }
    }
    &_btn_exit {
      cursor: pointer;
      :after {
        content: 'Volver';
        font-size: 0.875rem;
        font-family: map-get($fonts, roboto);
        font-weight: map-get($font-weight, medium);
        margin-left: 10px;
      }
      .icon {
        font-size: 0.875rem;
        margin-right: 0.675rem;
      }
    }
  } // claim_info
  .container_info {
    height: 100px;
    overflow: hidden;
    margin-bottom: 2.125rem;
    transition: 0.3s ease all;
    display: flex;
    flex-flow: column;
    .info {
      display: block;
      width: 100%;
      order: 1;
      margin: 0 0 2.5rem 0;
      &:last-child {
        margin: 0;
      }
      &_title {
        @extend .label_desktop;
        margin-bottom: 1rem;
      }
      &_list {
        display: block;
        list-style: none;
        color: map-get($colors, liberty-dark-gray);
        padding: 0;
        &_item {
          display: flex;
          line-height: 1.25rem;
          margin-bottom: 0.875rem;
          &:last-of-type {
            margin-bottom: 0;
          }
          &_title {
            display: block;
            min-width: 85px;
            font-weight: map-get($font-weight, medium);
          }
          &_info {
            display: block;
          }
        }
      } // info_list
    } // info
  } // container_info
  .expand_container {
    display: block;
  }
} // container

@media only screen and (min-width: 768px) {
  .container {
    display: flex;
    flex-flow: row wrap;
    color: map-get($colors, liberty-blue);
    margin-bottom: 3.25rem;
    .claim_info {
      display: flex;
      width: 100%;
      margin-bottom: 2.25rem;
      &_title {
        display: block;
        font-size: 1.625rem;
        font-weight: map-get($font-weight, regular);
      }
      &_text {
        display: block;
        font-size: 1.125rem;
        &_strong {
          font-weight: map-get($font-weight, medium);
        }
      }
      &_btn_exit {
        :after {
          content: 'Volver a consultar';
          font-size: 1rem;
        }
      }
    } // claim_info
    .container_info {
      height: auto;
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      margin: 0;
      .info {
        display: block;
        width: 50%;
        order: 1;
        font-size: 1.125rem;
        margin: 0 0 2.5rem 0;
        &:nth-child(1) {
          order: 1;
        }
        &:nth-child(2) {
          order: 3;
        }
        &:nth-child(3) {
          order: 2;
          margin: 0;
        }
        &_title {
          font-size: 1.125rem;
          display: block;
          margin: 0 0 1.375rem 0;
          font-weight: map-get($font-weight, medium);
        }
        &_list {
          display: block;
          list-style: none;
          margin: 0;
          color: map-get($colors, liberty-dark-gray);
          padding: 0;
          &_item {
            display: flex;
            line-height: 1.625rem;
            margin-bottom: 1rem;
            &:last-of-type {
              margin: 0;
            }
            &_title {
              display: block;
              min-width: 90px;
              font-weight: map-get($font-weight, medium);
            }
            &_info {
              display: block;
            }
          }
        } // info_list
      } // info
    } // container_info
    .expand_container {
      display: none;
    }
  } // container
}
