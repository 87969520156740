@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500");
@media only screen and (max-width: 450px) {
  .spare_parts_title {
    font-size: larger; } }

.spare_parts_message_save {
  text-align: center; }

.spare_parts_container {
  padding: 1.5rem 5% 1.5rem; }

.spare_parts_list {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0; }

.spare_parts_list_item {
  display: flex;
  margin-bottom: 10px; }
  @media only screen and (max-width: 450px) {
    .spare_parts_list_item {
      font-size: smaller; } }

.spare_parts_list_text {
  display: block; }
  .spare_parts_list_text_t {
    display: block;
    font-weight: 500;
    min-width: 160px; }
    @media only screen and (max-width: 450px) {
      .spare_parts_list_text_t {
        min-width: 130px; } }

.spare_parts_buttons_container {
  width: 100%;
  margin-top: 60px;
  display: inline-flex; }

.spare_parts_text_error {
  color: #d9011c;
  margin-top: 40px;
  text-align: center; }

.spare_parts_text_error_2 {
  color: #d9011c;
  text-align: left;
  margin-left: 10px; }
  @media only screen and (max-width: 450px) {
    .spare_parts_text_error_2 {
      font-size: smaller;
      text-align: center;
      margin-left: 10px; } }

.reception_data div {
  margin-left: 10px; }

.reception_data_item {
  margin-bottom: 10px; }
  .reception_data_item span {
    margin-right: 10px;
    min-width: 170px; }

.reception_data_vehicle {
  display: inline-block; }
  .reception_data_vehicle span.sep {
    margin-right: 10px;
    margin-left: 10px; }

.reception_data_input_required {
  color: #d9011c; }

.modal_error  {
  color: #d9011c;
  margin-top: 10px; }

.modal_container {
  flex-direction: row;
  display: flex; }

.modal_icon {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: auto;
  margin-bottom: auto;
  color: #006729; }

.modal_item {
  padding-left: 0px;
  padding-right: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%; }
  @media only screen and (max-width: 450px) {
    .modal_item {
      font-size: smaller; } }
