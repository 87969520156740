@import '../../../assets/css/variables.scss';

.header_cnt {
  background: map-get($colors, liberty-yellow);
  width: 100%;
  .header {
    width: 100%;
    padding: 1.25rem 1.625rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: row wrap;
    &-logo {
      order: 2;
      width: 82px;
      .img {
        width: 100%;
      }
    }
    &-menu {
      order: 2;
      font-size: 2rem;
    }
    &-prenav {
      order: 1;
      width: 100%;
      list-style: none;
      display: flex;
      justify-content: flex-end;
      margin: 0 0 1rem 0;
      padding: 0;
      .item {
        text-transform: uppercase;
        color: map-get($colors, liberty-blue);
        padding: 0.25rem 0.5rem;
        font-size: 0.75rem;
        border-right: 1px solid map-get($colors, liberty-blue);
        &:last-child {
          border: 0;
          padding-right: 0;
        }
        &-link {
          text-decoration: none;
          color: map-get($colors, liberty-blue);
          &:visited {
            color: map-get($colors, liberty-blue);
          }
        }
      }
    }
  }
}
.nav_cnt {
  background: map-get($colors, liberty-blue);
  min-height: 36px;
  .nav {
    display: flex;
    flex-flow: column;
    height: auto;
    max-height: 0;
    transition: max-height 0.3s ease-in;
    overflow: hidden;
    &.nav--expanded {
      max-height: 600px;
    }
    &-item {
      width: 100%;
      height: 100%;
      display: block;
      border-bottom: 1px solid map-get($colors, liberty-white);
      &:last-of-type {
        border: 0;
      }
      &.nav-item--expanded {
        .dropdown {
          height: auto;
        }
      }
      .title {
        height: 100%;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: flex-end;
        color: map-get($colors, liberty-white);
        font-size: 16px;
        font-weight: map-get($font-weight, light);
        text-decoration: none;
        padding: 1rem 0.875rem;
        .icon {
          color: map-get($colors, liberty-white);
          font-size: 0.75rem;
        }
      }
      .dropdown {
        list-style: none;
        padding: 0;
        margin: 0;
        height: 0;
        width: 100%;
        overflow: hidden;
        &-item {
          background: map-get($colors, liberty-blue);
          .link {
            display: block;
            padding: 1rem 2.25rem;
            color: map-get($colors, liberty-white);
            font-size: 16px;
            font-weight: map-get($font-weight, light);
            text-decoration: none;
            transition: 0.1s ease all;
            &:hover {
              background: map-get($colors, liberty-white);
              color: map-get($colors, liberty-blue);
            }
          }
        }
      }
      .contact {
        display: flex;
        justify-content: space-between;
        flex-flow: row nowrap;
        position: static;
        padding: 1.75rem 0.875rem 1rem;
        list-style-type: none;
        &-item {
          min-width: 70px;
          &:nth-child(2) {
            flex-grow: 1;
          }
          .link {
            display: flex;
            flex-flow: column;
            align-items: center;
            color: map-get($colors, liberty-white);
            font-size: 0.875rem;
            font-weight: map-get($font-weight, light);
            text-decoration: none;
            &:visited {
              color: map-get($colors, liberty-white);
            }
            .icon {
              display: flex;
              justify-content: center;
              align-items: center;
              color: map-get($colors, liberty-blue);
              background: map-get($colors, liberty-yellow);
              width: 36px;
              height: 36px;
              border-radius: 50%;
              font-size: 1.375rem;
              margin-bottom: 0.75rem;
            }
          }
        }
      }
    }
  }
}
.warning_cnt {
  color: map-get($colors, liberty-blue);
  background-color: map-get($colors, liberty-light-yellow);
  padding: 0.625rem 0;
  .warning {
    max-width: 1200px;
    width: 90%;
    margin: 0 auto;
    font-size: 0.625rem;
    .strong {
      font-weight: map-get($font-weight, medium);
    }
  }
}

@media only screen and (min-width: 991px) {
  .header_cnt {
    .header {
      max-width: 1140px;
      margin: 0 auto;
      padding: 1.625rem 0 1.25rem 0;
      &-logo {
        order: 1;
        width: 120px;
      }
      &-menu {
        display: none;
      }
      &-prenav {
        width: auto;
        order: 2;
        margin: 0;
        .item {
          padding: 0.675rem;
          font-size: 0.875rem;
        }
      }
    }
  }
  .nav_cnt {
    height: 52px;
    .nav {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      height: 100%;
      max-width: 1200px;
      margin: 0 auto;
      overflow: visible;
      &-item {
        border: 0;
        position: relative;
        width: 188px;
        height: 100%;
        &:hover {
          .dropdown {
            visibility: visible;
            opacity: 1;
            display: block;
          }
        }
        &:last-of-type {
          display: none;
        }
        .title {
          justify-content: center;
          align-items: center;
          height: 100%;
          padding: 1rem 0;
          //height: 52px;
          .icon {
            margin-left: 0.25rem;
          }
        }
        .dropdown {
          position: absolute;
          top: 100%;
          left: 50%;
          margin-top: 53px;
          margin-left: -80px;
          visibility: hidden;
          opacity: 0;
          display: block;
          height: auto;
          z-index: 2;
          border: 0;
          width: 160px;
          box-shadow: 0 6px 12px
            rgba(map-get($colors, liberty-black-rgb), 0.175);
          border-radius: 0 0 0.25rem 0.25rem;
          transition: 0.2s ease (opacity, visibility);
          &-item {
            .link {
              padding: 0.625rem 1rem;
              line-height: 1.25rem;
            }
          }
        }
      }
    }
  }
  .warning_cnt {
    padding: 1rem 0;
    .warning {
      font-size: 1rem;
    }
  }
} // media 768
