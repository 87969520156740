@import '../../../assets/css/variables.scss';
@import '../../../assets/css/general_styles.scss';

.tracking_container {
  max-width: 1200px;
  width: 90%;
  margin: 2rem auto 2.25rem auto;
  &_warning_message {
    text-align: left;
    margin-left: 1.875rem;
    font-size: 0.75rem;
    display: block;
  }
  &_rejected_message {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    width: 90%;
    max-width: 778px;
    padding: 1.5rem 1rem 2.25rem 1rem;
    border: 1px solid map-get($colors, liberty-blue);
    margin: 4.75rem auto 0 auto;
    &_icon {
      @extend .status_icon_rejected;
      margin: 0 0 2.125rem 0;
      &::before {
        font-size: 3.625rem;
      }
    }
    &_text {
      font-size: 1rem;
      color: map-get($colors, liberty-dark-gray);
      text-align: center;
    }
  }
  &_theft_message {
    font-size: 1rem;
    text-align: left;
    display: block;
    padding: 1.25rem 1.125rem 2rem 1rem;
    border: 1px solid map-get($colors, liberty-blue);
    margin: 4.75rem auto 0 auto;
    max-width: 964px;
    &_title {
      margin-bottom: 1.25rem;
      font-weight: map-get($font-weight, medium);
      color: map-get($colors, liberty-blue);
    }
    &_list {
      display: block;
      &_text {
        display: block;
        position: relative;
        padding-left: 1.5rem;
        color: map-get($colors, liberty-dark-gray);
        counter-increment: list-counter;
        &:first-child {
          margin-bottom: 1.375rem;
        }
        &:before {
          content: counter(list-counter) '. ';
          font-weight: map-get($font-weight, medium);
          color: map-get($colors, liberty-blue);
          position: absolute;
          left: 0;
        }
      }
    }
  }
}

.main_container {
  padding: 0 5% 1.5rem;
}
.modal_content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 0.875rem 0.75rem 1.625rem 0.75rem;
  &_icon {
    color: map-get($colors, liberty-blue);
    font-size: 1.75rem;
    margin-right: 0.75rem;
  }
  &_text {
    color: map-get($colors, liberty-blue);
    font-size: 0.75rem;
    .strong {
      display: block;
      font-size: 0.75rem;
      font-weight: map-get($font-weight, medium);
      margin-bottom: 0.375rem;
    }
  }
}

@media only screen and (min-width: 768px) {
  .tracking_container {
    &_warning_message {
      margin-left: 1.875rem;
    }
    &_rejected_message {
      flex-flow: row;
      padding: 1.75rem 2.625rem 1.75rem 2.625rem;
      margin: 4.75rem auto 0 auto;
      &_icon {
        margin: 0 2.25rem 0 0;
        &::before {
          font-size: 3.125rem;
        }
      }
      &_text {
        text-align: left;
      }
    }
    &_theft_message {
      padding: 1.25rem 1.875rem 2rem 1.875rem;
      &_title {
        margin-bottom: 1.25rem;
        font-weight: map-get($font-weight, medium);
        color: map-get($colors, liberty-blue);
      }
      &_list {
        display: block;
        &_text {
          display: block;
          color: map-get($colors, liberty-dark-gray);
          counter-increment: list-counter;
          &:first-child {
            margin-bottom: 1rem;
          }
          &:before {
            content: counter(list-counter) '. ';
            font-weight: map-get($font-weight, medium);
            color: map-get($colors, liberty-blue);
            margin-right: 0.675rem;
          }
          .strong {
            display: block;
            font-size: 0.8125rem;
            font-weight: map-get($font-weight, medium);
          }
        }
      }
    }
  }

  .modal_content {
    padding: 0.875rem 3.75rem 2.5rem 2.5rem;
    &_icon {
      font-size: 3rem;
      margin-right: 2.5rem;
    }
    &_text {
      font-size: 1.125rem;
    }
  }

  .consult_section {
    width: 500px;
    margin-left: 50px;
  }

  .consult_button {
    float: left;
    height: 30px;
    overflow: hidden;
    margin-bottom: 2.125rem;
    transition: 0.3s ease all;
    display: flex;
    flex-flow: column;

    width: auto;
    order: 1;
    font-size: small;
    padding-left: 10px;
    padding-right: 10px;
  }

  .consult_content {
    padding: 0.875rem 3.75rem 2.5rem 2.5rem;
    &_icon {
      font-size: 3rem;
      margin-right: 2.5rem;
    }
    &_text {
      margin-top: 8px;
      margin-right: 8px;
      float: left;
      display: block;
      list-style: none;
      color: map-get($colors, liberty-dark-gray);
      padding: 0;
    }
  }
}
